.timezone-dialog {
  .timezone-list {
    .table-body {
      font-size: 14px;

      .cell-content {
        font-family: Arial;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2a3236;
        padding-top: 0px;
        padding-bottom: 0px;
        width: 20%;
      }
    }
  }

  .custom-dialog-close-btn:hover {
    background-color: black;
  }
}
