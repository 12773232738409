.custom-dialog {
  & > div {
    & > div {
      max-width: none;
      position: relative;
      overflow: initial;

      .head {
        padding: 0;

        h2 {
          font-size: 16px;
          font-weight: bold;
          color: #2a3236;
          text-transform: uppercase;
        }
      }
    }
  }

  .dialog-title {
    padding: 0px;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #2a3236;
  }

  .template-preview-dialog-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0 20px;

    > span {
      text-transform: uppercase;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2a3236;
    }
  }

  .preview-dialog-content {
    padding: 0;
  }

  .preview-table {
    padding: 20px;
    width: 960px;

    table {
      border-collapse: collapse;
      border: 1px solid #b7c0c5;
      margin-bottom: 20px;
      padding-right: 20px;
      color: #2a3236;

      th {
        border: 1px solid #b7c0c5;
        text-align: left;
        padding: 8px 10px;
        background-color: #f6f9fa;
        font-size: 12px;
        font-weight: normal;
      }

      td {
        border: 1px solid #b7c0c5;
        padding: 8px 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .preview-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 640px;
    > span {
      padding: 24px;
      font-size: 18px;
      font-weight: 700;
      color: #2a3236;
      &:hover {
        cursor: pointer;
      }
      &:first-child {
        border-right: 1px solid #2a3236;
      }
    }
  }

  .dialog-close-btn {
    padding: 1px;
    background-color: black;
    color: white;
    border: solid 2.1px white;
    position: absolute;
    top: -12px;
    right: -12px;
    z-index: 999;
    &:hover {
      background-color: black;
    }
  }
}
